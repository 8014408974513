import React from 'react'
import {InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Img} from './About.elements'
import { Container, Button } from '../../globalStyles'
import { Link } from 'react-router-dom'

 const AboutSection = ({ 
    primary,
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart,
    start
}) => {
    return (
        <>
           <div style={{ padding: 70}}>
               <br/>
               Collapp is a social media platform focused on people with creative talents and consumers looking for unique items.<br/>
               <br/>
               Users with creative talents can use our platform to offer and sell their hand-crafted items or to simply get inspired by other creative people. Consumers can use our platform in their search for unique, handcrafted items with a personal touch.<br/>
               <br/>
               Our platform distinguishes through its focus on collaboration between the creator and the consumer, resulting in unique items perfectly matching the personal wishes of each consumer.<br/>
               <br/>
               This is achieved through jointly drafting and agreeing on a tailor-made production plan comprising a pre-defined number of steps, with partly payments after completion of each step to satisfaction of the consumer. By doing so, risk is fairly distributed between the creator (putting a lot of effort and love in production) and the consumer (wishing certainty that the end product meets its specific wishes).<br/>
               <br/>
               We hope you enjoy our platform as much as we enjoyed making it and please let us know what you think!<br/>
               <br/>
               The Collapp Team<br/>
               <br/>


           </div>
        </>
    )
}

export default AboutSection;