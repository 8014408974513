export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: false,
    lightTextDesc: false,
    headline: "WE ARE COLLAPP",
    description: 'We have developed the first “sociartistic platform”, a creative social marketplace to connect creatives with other creatives & consumers.\n' +
        'Creatives can connect to exchange experience and knowledge, as well as for creative collaborations.\n' +
        'Consumers can connect with creatives for handmade items with a personal touch!\n',
    imgStart: '',
    img: require('../../images/Picture9.svg'),
    alt: 'Credit Card',
    start: '',
    left: 'false'
};

export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Instant Setup',
    headline: 'Extremely quick onboarding process',
    description:
        "Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/svg-2.svg'),
    alt: 'Vault',
    start: ''
};

export const step1 = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: true,
    lightTextDesc: false,
    headline:
        'Mila is looking for a unique item, perfectly matching your personal taste and preferences\n',
    buttonLabel: 'View Case Study',
    imgStart: 'start',
    img: require('../../images/Picture1.svg'),
    alt: 'Vault',
    start: 'true',
    left: false
};
export const step2 = {
    primary: false,
    lightBg: false,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    headline:
        'Luca has a creative talent, and wants to share it with others\n',

    buttonLabel: 'View Case Study',
    imgStart: 'start',
    img: require('../../images/Picture2.svg'),
    alt: 'Vault',
    start: 'true',
    left: true
};
export const step3 = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: true,
    lightTextDesc: false,
    headline:
        'Mila uses Collapp in search of someone with the creative ability to make her unique item, and finds Luca’s profile\n',
   
    buttonLabel: 'View Case Study',
    imgStart: 'start',
    img: require('../../images/Picture3.svg'),
    alt: 'Vault',
    start: 'true',
    left: false
};
export const step4 = {
    primary: false,
    lightBg: false,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    headline:
        'Mila and Luca together use the app to make a step-by-step creation plan, with a (partial) payment after finalisation of each step\n',
   
    buttonLabel: 'View Case Study',
    imgStart: 'start',
    img: require('../../images/Picture4.svg'),
    alt: 'Vault',
    start: 'true',
    left: true
};
export const step5 = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: true,
    lightTextDesc: false,
    headline:
        'Luca starts creating, and updates Mila regularly on progression through the in-app chat function\n',
   
    buttonLabel: 'View Case Study',
    imgStart: 'start',
    img: require('../../images/Picture5.svg'),
    alt: 'Vault',
    start: 'true',
    left: false
};
export const step6 = {
    primary: false,
    lightBg: false,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    headline:
        'Mila pays part of the total price, only after Adam has completed a certain pre-defined step to Mila’s satisfaction\n',
   
    buttonLabel: 'View Case Study',
    imgStart: 'start',
    img: require('../../images/Picture6.svg'),
    alt: 'Vault',
    start: 'true',
    left: true
};
export const step7 = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: true,
    lightTextDesc: false,
    headline:
        'The item is finished! Mila & Luca together agree on a method of shipping, for instance per post or pick-up in person to celebrate the ‘collapporation’\n',
   
    buttonLabel: 'View Case Study',
    imgStart: 'start',
    img: require('../../images/Picture7.svg'),
    alt: 'Vault',
    start: 'true',
    left: false
};
export const step8 = {
    primary: false,
    lightBg: false,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    headline:
        'Mila provides in-app feedback on the collaboration with Rami, for instance through a picture for Luca’s profile!\n',
   
    buttonLabel: 'View Case Study',
    imgStart: 'start',
    img: require('../../images/Picture8.svg'),
    alt: 'Vault',
    start: 'true',
    left: true
};

export const homeObjFour = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Secure Database',
    headline: 'All your data is stored on our secure server',
    description:
        'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/svg-3.svg'),
    alt: 'Vault',
    start: 'true'
};