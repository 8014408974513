import React from 'react';
import {homeObjOne, homeObjTwo} from './Data';
import AboutSection from "../../components/About/About";

function About() {
  return (
    <>
      <AboutSection {...homeObjTwo} />
    </>
  );
}

export default About;