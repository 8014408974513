export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTopLine: true,
    lightTextDesc: false,
    topLine: 'Sign up Today',
    headline: '\n' +
        'Privacy statement Creative Collaborations B.V.\n' +
        'This Privacy Statement applies to all (mobile) websites, (mobile) applications and/or (platform) applications of Creative Collaborations B.V. (hereinafter also, “we” or “us”). This Privacy Statement describes how we, as a controller, collect, share, use and/or otherwise process data that relates to or identifies you (“personal data”), in accordance with the General Data Protection Regulation (“GDPR”) . This Privacy Statement does not apply to third party websites or services referenced on our platform.\n' +
        'We reserve the right to amend this Privacy Statement to ensure compliance with applicable laws and regulations. We therefore recommend that you consult this Privacy Statement regularly so that you are aware of any changes.\n' +
        '\n' +
        'Who we are\n' +
        'Creative Collaborations B.V.\n' +
        'Govert Flinckstraat 229-2V\n' +
        '1073 BW Amsterdam\n' +
        'info.collapp@gmail.com\n' +
        '\n' +
        'Personal data storage and usage\n' +
        'Collapp offers a platform, accessible via a (mobile) website and application, on which hand-made products can be offered and developed for sale and where Collapp also facilitates related services, such as payments and assessments.\n' +
        '\n' +
        'In most cases we obtain the personal data from you, for example when you contact us, visit our platform or create a Collapp account to use our services. We explain this in more detail below.\n' +
        '\n' +
        'Collapp-account\n' +
        'To use our services, you must create a Whoppah account as a user. In this context, we request the following personal data:\n' +
        '-\tName\n' +
        '-\tAddress\n' +
        '-\tE-mail address\n' +
        '-\tAge\n' +
        '\n' +
        'Optionally, it is possible to enter your photo and/or interests and preferences. Furthermore, placed, viewed and saved advertisements and/or searches, or previous reviews can be visible within the Collapp account.\n' +
        '\n' +
        'Platform visits\n' +
        'When you visit our platform or log in to your Collapp account, we process the IP address and data of the (mobile) device with which you visit our platform, such as device or\n' +
        ' \n' +
        '\n' +
        'browser type. Insofar as you have given permission for this, we also process the location data of your (mobile) device. The main purpose of this is to facilitate the delivery of any purchased or sold products, but also, for example, to be able to offer location-based, relevant advertisements.\n' +
        '\n' +
        'We also process personal data when you contact us (via e-mail, chat or otherwise) in order to handle your question or complaint and/or to further develop and improve the platform.\n' +
        '\n' +
        'Marketing & communication\n' +
        'If you have a Collapp account, we will also keep you informed about new services\n' +
        'or functionalities within the platform and the availability of upgrades or new versions of the platform. This is done, among other things, by sending online newsletters. It is possible to unsubscribe from such newsletters at any time via an \'unsubscribe\' link in the relevant e-mail.\n' +
        '\n' +
        'If you have specified certain interests and preferences in your account, such as a product that you are looking for, we will also keep you informed of (potentially) interesting advertisements or products, provided that you have given permission for this. In this way, displayed advertisements are personalized based on your search behaviour, interests, (style) preferences and the like. Based on this, we can show you targeted (potentially) interesting advertisements, whether or not within a certain location, and better meet your wishes.\n' +
        '\n' +
        'Google Analytics\n' +
        'In order to optimize and improve the content and use of our website, we have access to the surfing behavior of website visitors. We do this with the help of Google Analytics. This functionality is set up in a privacy-friendly manner, whereby we have concluded a processing agreement with Google, the IP address is anonymized and no data is shared with Google.\n' +
        '\n' +
        'Sharing of personal data\n' +
        'We treat personal data provided to us confidentially and, in principle, do not share it with third parties, except in the following situations:\n' +
        '1.\tCollapp also uses third parties for the provision of its services, for example IT suppliers and deliverers (\'processors\'). We have made agreements with such processors in processing agreements regarding the careful handling and security of personal data.\n' +
        '2.\tCollapp may also provide or disclose certain personal data to third parties if we are or become required to do so by applicable law or court order.\n' +
        '3.\tWhen you log in to our platform via your Facebook or other social media account, the relevant social media platform gains access to the personal data linked to your Collapp account. The privacy policy of the relevant social media platform applies to the processing of your personal data in that situation. Collapp has no influence on this.\n' +
        '4.\tCollapp may share personal data with third parties in order to prevent, detect, investigate or mitigate fraud, security breaches or other potentially prohibited or illegal activities. Where appropriate, we will make agreements in processing agreements regarding the careful handling and security of personal data.\n' +
        '\n' +
        'In principle, personal data will not be passed on to parties or countries outside the EU European Economic Area (EEA). If the transfer of personal data to parties or countries outside the EEA is necessary, we will at all times comply with the legal requirements.\n' +
        '\n' +
        '\n' +
        'Data security\n' +
        'We have taken appropriate technical and organizational measures to protect your protect personal data as optimally as possible against unauthorized access and misuse.\n' +
        '\n' +
        'Timing of data storage\n' +
        'We do not store your personal data longer than necessary for the purposes for which the personal data was obtained. For example, the data of your Collapp account will in principle be kept until cancellation of that account. The data will then be deleted immediately.\n' +
        '\n' +
        'In some cases we need to keep certain personal data longer. This may be the case, for example, when a claim is still outstanding, specific statutory retention periods apply or (legal) disputes are pending.\n' +
        '\n' +
        'Your rights\n' +
        'You can view, check and/or change your personal data yourself via the settings in your Collapp account. In addition, you also have the right to request us to inspect, change, supplement, request deletion or data transfer of your personal data.\n' +
        '\n' +
        'You can also submit a request to limit a certain data processing, or object to this. A request to this effect can be sent to us in writing or by e-mail via the contact details above. We will respond within the legal time limits upon receipt of such a request.\n' +
        '\n' +
        'If you send us a request to delete your (personal data in your) Collapp account, we will delete your personal data as soon as possible, no later than 1 month after receipt of the request.\n' +
        '\n' +
        'Questions\n' +
        'If you have any questions, comments or complaints about the processing and/or protection of your personal data by us, you can contact us in writing or by e-mail using the contact details above. For the sake of completeness, we also point out that you have the right to submit a complaint to the supervisory authority. The Dutch supervisory authority with regard to data protection is the “Authority for Personal Data”.\n' +
        '\n' +
        'Applicable law\n' +
        'This Privacy Statement, as well as all disputes arising out of or related to this Privacy Statement, are exclusively governed by Dutch law.\n' +
        '\n' +
        'Amsterdam, January 2023\n',
    description:
      'Get access to our exclusive diamond king package. Limited quantity available.',
    buttonLabel: 'Sign Up',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Credit Card'
  };
  
  export const homeObjTwo = {
    lightBg: false,
    lightText: true,
    lightTopLine: true,
    lightTextDesc: true,
    topLine: '100% Secure',
    headline: 'Stay protected 24/7 anywhere anytime',
    description:
      'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Vault'
  };
  
  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTopLine: true,
    lightTextDesc: false,
    topLine: 'Easy Setup',
    headline: 'Super fast and simple onboarding process',
    description:
      "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
    buttonLabel: 'Start Now',
    imgStart: 'start',
    img: require('../../images/svg-1.svg'),
    alt: 'Vault'
  };
  
  export const homeObjFour = {
    lightBg: false,
    lightText: true,
    lightTopLine: true,
    lightTextDesc: true,
    topLine: 'Data Analytics',
    headline: 'Every transaction is stored on our secure cloud database',
    description:
      'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/svg-1.svg'),
    alt: 'Vault'
  };