import React from 'react'
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    ImgWrapper,
    Img
} from './TermsAndConditions.elements'
import {Container, Button} from '../../globalStyles'
import {Link} from 'react-router-dom'

const TermsAndConditionsSection = ({
                                       primary,
                                       lightBg,
                                       topLine,
                                       lightTopLine,
                                       lightText,
                                       lightTextDesc,
                                       headline,
                                       description,
                                       buttonLabel,
                                       img,
                                       alt,
                                       imgStart,
                                       start
                                   }) => {
    return (
        <>
            <div style={{padding: 70}}>
                <h1>1. Definitions<br/></h1>
                1.1. Collapp: the private company Creative Collaborations B.V., with its registered office in Amsterdam
                and registered in the trade register of the Chamber of Commerce under number 87323354.<br/>
                1.2. Buyer: any natural person who concludes a purchase agreement with a Seller via the Platform with
                regard to the purchase of one or more Product(s).<br/>
                1.3. Seller: any natural or legal person who offers one or more Product(s) via the Platform<br/>
                1.4. User: any natural person, including a Buyer and Seller, who visits the Platform and uses the
                Service(s) offered on it.<br/>
                1.5. Account: the personal profile of a User on the Platform with which the Collapp Service(s) can be
                used.<br/>
                1.6. Platform: the downloadable (mobile) application(s) of Collapp.<br/>
                1.7. Third Party Provider: a third party, not affiliated with Collapp, that offers certain services
                through the Platform, such as Stripe for payment services.<br/>
                1.8. Service(s): the services offered by Collapp, whether or not via a Third Party Provider, on the
                Platform or otherwise, including but not limited to, placing advertisements on the Platform in order to
                offer Products for sale and facilitating the payments related to the purchase/sale.<br/>
                1.9. IP rights: all intellectual property rights, both registered and unregistered, including but not
                limited to, patent rights, copyrights (including rights to source and object codes of software),
                database rights, trademark rights, trade name rights and rights related to know-how and trade
                secrets.<br/>
                1.10. Stripe: the payment platform of the payment service Stripe with the payment methods offered on it.<br/>
                1.11. Product: a product in a specific, available (sub)category that can be offered by a Seller on the
                Platform.<br/>
                1.12. Transaction costs: the costs owed by the Buyer to Collapp in the amount of to effectuate the
                purchase transaction with the Seller.<br/>
                1.13. Requirements: these terms of use.<br/>
                <br/>
                <h1>2. Applicability<br/></h1>
                2.1. These Terms are applicable to each use by a User of the Platform and/or the Services, as well as
                the maintenance thereof by Collapp. When using Third Parties for certain Services, Collapp will assure
                the General Conditions are also applicable to the respective parties.<br/>
                2.2. By creating an Account on the Platform, Users declare themselves bound to these Conditions of
                Collapp.<br/>
                2.3. Deviations, adjustments or supplements to these Terms are only valid when agreed explicitly in
                writing.<br/>
                2.4. Collapp exclusively reserves the right to adjust the Terms from time to time. Under these
                circumstances, Users must accept the adjusted Terms before making any further use of the Platform and
                the offered Services.<br/>
                2.5. If any adjustments to these Terms are void or voided, the remaining provisions of these Terms will
                remain in full force and effect. In that case, Collapp will replace the void or voided provision, taking
                into account the purpose and purport of the void or voided provisions as much as possible.<br/>
                2.6. If the User acts in violation of one or more provisions in these Terms, Collapp is entitled, in
                addition to any other (legal) remedies, to limit or (temporarily) suspend the User's activities on the
                Platform with immediate effect, in whole or in part. This includes stopping and/or removing the Account,
                as well as refusing access to the Service(s), without Collapp being liable or liable for damages to the
                User.<br/>
                <br/>
                <h1>3. The Account<br/></h1>
                3.1. In order to use the Collapp Services offered via the Platform, the User must create an Account.
                When creating an Account, the User can choose to log in via another social media platform account of the
                User. Users under the age of 18 require permission from their legal representative (guardian or parent)
                in order to use the Services.<br/>
                3.2. The User is responsible for the careful confidentiality of his user name and password. In that
                context, the User is also liable for any use of the Services via its Account, including all (legal) acts
                performed under the Account.<br/>
                3.3. The User is not allowed to have multiple Accounts that directly or indirectly belong to the same
                User.<br/>
                3.4. The User is also responsible for the completeness and correctness of the data in the Account and
                for updating it in the event of changes.<br/>
                3.5. In order to use the online payment service Stripe, the User must have a current account with a
                Dutch/European bank. Every Seller is obliged to identify himself as such and to provide further
                information on request when creating an Account. Collapp has the right to change the status of a Seller
                Account if there is reasonable cause to do so, for example if a Seller has not registered as such.<br/>
                3.6. Collapp has the right at all times to refuse to create an Account, or to (temporarily) block or
                delete an Account that has already been created. This may be the case, for example, if the User lives or
                is established in a country where the Services cannot or cannot be provided, there is (a suspicion of)
                fraud or (suspected) violation of these Terms or the applicable laws or regulations. Such a refusal,
                block or removal will never give the User a right to (damage) compensation.<br/>
                3.7. The User can request Collapp to delete the Account by e-mail. In that case: (i) the User cannot
                make longer use of the Services offered via the Platform and (ii) Collapp will delete the data in the
                Account no later than one month after receipt of such a request, subject to the provisions of the
                following: if Collapp receives a request from the User to delete the Account, while payment and/or
                delivery obligations are still outstanding, the User must first comply before the Account can be
                deleted.<br/>
                <br/>
                <h1>4. Content<br/></h1>
                4.1. By posting Content, Users grant Collapp a non-exclusive, worldwide, royalty-free, irrevocable,
                sub-licensable, perpetual license to use, display, edit, modify, reproduce, distribute, store and
                prepare derivative works of your Content. This allows us to provide the Services and promote Collapp and
                its Services in general, in all formats and through all channels. Users agree to not enforce any moral
                or publicity rights against Collapp as a result of our use their Content. In addition, Users acknowledge
                our legitimate interest in using the Content in accordance with the scope of this license, to the extent
                that it contains personal information.<br/>
                4.2. Collapp respects intellectual property rights and strives to follow applicable legal procedures for
                removing infringing content from the Services. If any Content is accused of infringing another's
                intellectual property, we will take appropriate action, from disabling the material if we receive proper
                notice of infringement that complies with our policies, to terminating a User’s account.<br/>
                4.3. Users agree to not post Content that is demeaning, threatening, defamatory, obscene, vulgar, or
                otherwise objectionable. In addition, Users agree not to post false or misleading content or content
                that uses the Platform in a fraudulent or deceptive manner.<br/>
                <br/>
                <h1>5. Acceptance & payment<br/></h1>
                5.1. There is a valid sale of a Product in the following cases: (i) if a Product has been developed in
                accordance to the upfront agreed development contract and each payment step has been completed (ii) if
                the Buyer has pressed on the buy button and payment of (part of) the asking price is made, or (iii) if
                the Buyer has made an offer for an off-the-shelve product that has been accepted by the Seller within 48
                hours after the offer was placed and then paid for by the Buyer.<br/>
                5.2. For to-be-developed products, Collapp works with a step-by-step payment process. Before product
                development commences, Buyer and Seller agree to a pre-defined development and payment scheme, the
                purchase agreement. Buyers pay each pre-defined step beforehand, but final payment will only be settled
                after confirmation of the Buyer that the step has been carried out as agreed.<br/>
                5.3. In the event that the Buyer has pressed the Buy Now Butten and the purchase price meets the
                instructions of Collapp, the Seller automatically accepts this offer and agrees to the sale at price and
                the product must be delivered to the Buyer by the delivery method chosen by the buyer. When Buyer and
                Seller have reached a sale (offer and acceptance) of a Product in accordance with the provisions of this
                article, Collapp confirms the purchase, also including a request for payment of (part of) the Purchase
                Price, supplemented with Transaction costs in accordance with the specified payment instructions.<br/>
                5.4. Buyers & Sellers acknowledge that Collapp only has a facilitating and supporting role through the
                Platform by offering the Services and is in no way a party to any purchase agreement between Buyer and
                Seller; Buyer and Seller indemnify Collapp against any (damage) claim from Seller respectively Buyer,
                resulting from or related to the agreement and/or performance of the Purchase Agreement between Buyer
                and Seller. Collapp is therefore (among other things) not obliged to demand compliance by the Seller
                with regard to the actual delivery of the Product.<br/>
                5.5. Buyer and External Seller indemnify Collapp against any (damage) claim from Seller or Buyer,
                respectively, as a result of the sale not being able to materialise. This includes a sale not
                materialising due to technical reasons (such as malfunctions or bugs) on the Platform that entail
                complications in the execution of the sale.<br/>
                5.6. Buyer and Seller are prohibited from agreeing direct payment of the Purchase Price and/or accepting
                direct payment of the Purchase Price. Amounts received must in that case be refunded. In the event of a
                violation of this provision, Collapp may, at its option, hold both the Buyer and the Seller liable for
                compensation for all damage and costs incurred by Collapp as a result, which in any event amount to an
                amount equal to the Transaction Fees and lost by Collapp (as applicable).<br/>
                <br/>
                <h1>6. Delivery, complaint & payment<br/></h1>
                6.1. As soon as the Buyer has paid the Purchase Price and Transaction Costs, the Seller will receive
                a (payment) confirmation. If the Product is sent by the Seller by parcel post, the Seller must
                ensure the correct and timely delivery of the Product to the Buyer at the latest within three (5)
                working days after receipt of confirmation from Collapp and instructions from the Buyer. When
                creating an Account, the Seller indicates which shipping methods it offers. The Seller sees the
                compensation he will receive for this, together with the sales proceeds. In addition, Seller can
                indicate whether it prefers personal pick-up or drop-off by or to the Buyer.<br/>
                6.2. Sold Products must be sent with a provider that offers a Track & Trace system. Seller bears the
                risk of damage and loss of the Product during shipping. Collapp cannot be held liable for this.<br/>
                6.3. The Buyer has 10 working days after payment of the Purchase Price to indicate to the Seller
                whether he has received the Product in good order and that the Product meets the conditions as
                defined in the Purchase Agreement. If the Buyer complains in time payment to the External Seller is
                (temporarily) suspended.<br/>
                6.4. As soon as the Buyer has confirmed that the delivery has been successful or that the complaint
                period of Article 5.3 has expired, the Seller will receive a confirmation from Collapp by e-mail of
                the forthcoming payment to the Seller of the final settlement of the Purchase Price. Payment will
                take place no later than ten (10) days after receipt of the confirmation to the bank account number
                specified by the Seller.<br/>
                6.5. Buyers bear full risk of the Product not meeting the conditions as defined in the Purchase
                Agreement. If a Buyer wants to ensure this, they can choose for personal pick-up to assess whether
                the Product meets the Buyer’s expectation, and if not choose not to pay the final step of the
                pre-defined Purchase Agreement.<br/>
                <br/>
                <h1> 7. Disputes & refunds<br/></h1>
                7.1. The Buyer is obliged to inspect the Product for defects immediately upon receipt. In doing so,
                the Buyer should examine the quality and/or quantity of the Product.<br/>
                7.2. In the event of questions and/or complaints, the Buyer must contact the relevant Seller
                directly. Seller warrants that inquiries or complaints received from Buyers will be answered within
                forty-eight (48) hours. Both Buyer and Seller acknowledge that Collapp shall not be liable in any
                way, including but not limited to any faultiness of the purchased Product.<br/>
                7.3. Both Buyers and Sellers acknowledge that Collapp is not responsible for termination or returns
                should a Product not meet the expectations of the Buyer. In case of a dispute between the Seller and
                the Buyer about the Product, whether or not a Product has been sent or returned, the External Seller
                and the Buyer must try to resolve this between themselves. Buyers and Sellers agree to discuss
                potential suitable solutions and (partial) refunds in good faith. Collapp reserves the right to warn
                Users of the Platform in the case that certain Buyers or Sellers have been in multiple disputes in
                multiple instances during purchases.<br/>
                7.4. If the Seller can show a proof of shipment and the Buyer cannot demonstrate that there is a
                non-conformity, payment will be made. If the Seller cannot show proof of shipment and/or the Buyer
                makes it plausible that there is non-conformity, final payment will be suspended for the duration of
                the dispute. In the event of a dispute regarding the (timely) shipment/delivery of a Product, the
                Track & Trace code of the carrier is leading, subject to the possibility of proof to the
                contrary.<br/>
                <br/>
                <h1> 8. Intellectual Property<br/></h1>
                8.1. All IP rights with regard to the Platform, including further developments thereof, and the
                database with advertisements, are vested exclusively in Collapp or its licensors.<br/>
                8.2. Under these Terms, the User only acquires a limited, non-exclusive, non-(sub-)licensable and
                non-transferable right to use the Services offered on the Platform. The User acknowledges that all
                IP rights relating to the Platform, the collection of Content, the design of the Platform are and
                remain the property of Collapp and may not be used in any way by the User - without the explicit,
                prior written consent of Collapp.<br/>
                8.3. The User is not permitted to modify, decompile, reproduce (the code), translate or otherwise
                subject the Platform to reverse engineering.<br/>
                8.4. When using the Platform, the User will also refrain from:<br/>
                8.4.1. directly or indirectly copying, reusing or multiplying (parts of) the Platform;<br/>
                8.4.2. directly or indirectly copying, reusing or reproducing Content<br/>
                8.4.3. changing or removing (or having removed) any designation or reference in the Platform to
                Collapp, Collap´s IP rights, the confidential nature thereof, or any other reference made by
                Collapp;<br/>
                8.4.4. spreading or causing the distribution of viruses, worms, Trojan horses, rootkits, spyware,
                hoaxes or other forms of malware;<br/>
                8.4.5. any other actions that violate these Terms and/or any applicable law or regulation. Nothing
                in these Terms is intended to transfer any IP right to the User. User will refrain from actions that
                (may) infringe the IP rights of Collapp or third parties.<br/>
                <br/>
                <h1> 9. Maintenance Platform<br/></h1>
                9.1. Despite the fact that the Platform has been composed with great care, Collapp does not
                guarantee that the Platform works without errors or is without (technical) defects. The Platform and
                all applicable functionalities are offered 'as is'. <br/>
                9.2. Collapp continuously strives to resolve any defects in the Platform as soon as possible in
                order to ensure optimum availability of the Platform. Collapp has the right at all times, without
                prior notice, to (temporarily) suspend access to the Platform or to limit its use, insofar as this
                is necessary for maintenance, or to make updates, releases, adjustments or improvements. to be able
                to implement in the Platform. This never entails a right to (damage) compensation for the User
                towards Collapp.<br/>
                9.3. Collapp offers no guarantee whatsoever with regard to the preservation or leaving unchanged of
                certain functionalities or Services of the Platform after an update or new release.<br/>
                <br/>
                <h1> 10. Protection of personal data<br/></h1>
                10.1. To create an Account and use the Services, Collapp will process the User's personal data.
                The processing of personal data takes place in accordance with the Collapp Privacy Statement and
                applicable laws and regulations in the field of the protection of personal data.<br/>
                10.2. Insofar as Collapp also uses Third Party Providers when providing the Services, certain
                personal data will be shared with those Third Party Providers to the extent necessary for the
                performance of the Service. The privacy statement of the relevant Third Party Provider then also
                applies to that processing.<br/>
                10.3. If and insofar as the User receives certain personal data of another User from Collapp,
                the User guarantees to process that personal data exclusively for the performance of or in
                connection with the purchase agreement with the other User, with due observance of the
                applicable laws and regulations on the field of personal data protection.<br/>
                <br/>
                <h1> 11. Communications and Reviews<br/></h1>
                11.1. External Seller and Buyer can communicate with each other via the Platform via a chat
                service. Collapp can view these chat messages and intervene in the communication to help. This
                applies without prejudice to the provisions of Article 6.2.<br/>
                11.2. Collapp stores all communication messages and may access and use them to (i) support Buyer
                and Seller in case of questions or complaints in the context of a dispute procedure, (ii) assess
                whether Seller or Buyer meet the requirements of has complied with the purchase agreement and/or
                these Conditions, (iii) to analyze (the use of) the chat service and to be able to improve
                it.<br/>
                11.3. Communication messages from the chat service will be kept for the duration of the answer
                to the question or complaint, or until a dispute is resolved. The data will then be deleted or
                kept in an anonymised form, the latter only being used for analysis, improvement and
                optimization of the chat service.<br/>
                11.4. Seller and Buyer shall refrain from including discriminatory, offensive, suggestive and/or
                offensive language in their communications.<br/>
                <br/>
                <h1> 12. Liability<br/></h1>
                12.1. To the extent permitted by law, Collapp does not accept any liability for any damage
                and/or costs resulting from the use of the Platform and/or the Services.<br/>
                12.2. Collapp is furthermore never liable for the performance of (one of the) Services by Third
                Party Providers, or for any disputes between a User and a Third Party Provider. When using Third
                Party Providers, the general (delivery) conditions of the relevant Third Party Provider also
                apply.<br/>
                12.3. The liability limitations as referred to in Article 11.1 lapse if the damage is the result
                of intent or willful recklessness on the part of Collapp.<br/>
                <br/>
                <h1> 13. Final Provisions<br/></h1>
                13.1. Collapp is, if reasonably justified by the circumstances of the case, authorized to
                suspend the performance of an agreement between Collapp and the User or to dissolve that
                agreement in whole or in part with immediate effect, if and insofar as the User has fulfilled
                its obligations under that commitment (including the provisions of these Terms and Conditions)
                is not complied with, or is not complied with on time or in full, or circumstances have become
                known to Collapp after the conclusion of that commitment that give Collapp good grounds to fear
                that the User will not fulfill its obligations.<br/>
                13.2. These Conditions are exclusively governed by Dutch law.<br/>
                13.3. Any disputes between the User and Collapp arising from or related to these Terms and
                Conditions will be submitted to the competent court within the district of Collapp´s place of
                business, unless mandatory provisions of law designate another competent court for this.<br/>
                Amsterdam, February 2023<br/>
                <br/>
                <br/>
            </div>
        </>
    )
}

export default TermsAndConditionsSection;