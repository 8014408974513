import React from 'react';
import { InfoSection } from '../../components';
import { homeObjOne } from './Data';
import StatementSection from "../../components/Statement/Statement";

function Privacy() {
  return (
    <>
      <StatementSection {...homeObjOne} />
    </>
  );
}

export default Privacy;