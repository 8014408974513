import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom' 
import GlobalStyles from './globalStyles'
import { Navbar, Footer } from './components';
import Home from './pages/HomePage/Home';
import SignUp from './pages/SignUp/SignUp';
import ScrollToTop from './components/ScrollToTop';
import CustomerService from "./pages/CustomerService/CustomerService";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import Privacy from "./pages/Privacy/Privacy";
import About from "./pages/About/About";

function App() {
  return (
    
      <Router>
          <GlobalStyles />
          <ScrollToTop />
          <Navbar />
          <Switch>
            <Route path='/' exact component={Home} />
              <Route path='/About' component={About} />
            <Route path='/sign-up' component={SignUp} />
            <Route path='/terms-of-service' component={TermsOfService} />
            <Route path='/privacy' component={Privacy} />
            <Route path='/customer-service' component={CustomerService} />
          </Switch>
          <Footer />
      </Router>
        
    
  );
}

export default App;
