import React from 'react'
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    ImgWrapper,
    Img
} from './Statement.elements'
import {Container, Button} from '../../globalStyles'
import {Link} from 'react-router-dom'

const StatementSection = ({
                              primary,
                              lightBg,
                              topLine,
                              lightTopLine,
                              lightText,
                              lightTextDesc,
                              headline,
                              description,
                              buttonLabel,
                              img,
                              alt,
                              imgStart,
                              start
                          }) => {
    return (
        <>
            <div style={{padding: 70}}>
                <h1> Privacy statement Creative Collaborations B.V. <br/></h1>
                This Privacy Statement applies to all (mobile) websites, (mobile) applications and/or (platform)
                applications of Creative Collaborations B.V. (hereinafter also, “we” or “us”). This Privacy Statement
                describes how we, as a controller, collect, share, use and/or otherwise process data that relates to or
                identifies you (“personal data”), in accordance with the General Data Protection Regulation (“GDPR”) .
                This Privacy Statement does not apply to third party websites or services referenced on our
                platform.<br/>
                We reserve the right to amend this Privacy Statement to ensure compliance with applicable laws and
                regulations. We therefore recommend that you consult this Privacy Statement regularly so that you are
                aware of any changes.<br/>
                <br/>
                <h1> Who we are<br/></h1>
                Creative Collaborations B.V.<br/>
                Rapenburgerstraat 87-1<br/>
                1011 VL Amsterdam<br/>
                info.collapp@gmail.com<br/>
                0621256613<br/>
                <br/>
                <h1> Personal data storage and usage<br/></h1>
                Collapp offers a platform, accessible via a (mobile) website and application, on which hand-made
                products can be offered and developed for sale and where Collapp also facilitates related services, such
                as payments and assessments.<br/>
                In most cases we obtain the personal data from you, for example when you contact us, visit our platform
                or create a Collapp account to use our services. We explain this in more detail below.<br/>
                <br/>
                <h1> Collapp-account<br/></h1>
                To use our services, you must create a Whoppah account as a user. In this context, we request the
                following personal data:<br/>
                - Name<br/>
                - Address<br/>
                - E-mail address<br/>
                - Age<br/>
                <br/>
                Optionally, it is possible to enter your photo and/or interests and preferences. Furthermore, placed,
                viewed and saved advertisements and/or searches, or previous reviews can be visible within the Collapp
                account.<br/>
                <br/>
                <h1>Platform visits<br/></h1>
                When you visit our platform or log in to your Collapp account, we process the IP address and data of the
                (mobile) device with which you visit our platform, such as device or<br/>
                browser type. Insofar as you have given permission for this, we also process the location data of your
                (mobile) device. The main purpose of this is to facilitate the delivery of any purchased or sold
                products, but also, for example, to be able to offer location-based, relevant advertisements.<br/>
                We also process personal data when you contact us (via e-mail, chat or otherwise) in order to handle
                your question or complaint and/or to further develop and improve the platform.<br/>
                <br/>
                <h1>Marketing & communication<br/></h1>
                If you have a Collapp account, we will also keep you informed about new services<br/>
                or functionalities within the platform and the availability of upgrades or new versions of the platform.
                This is done, among other things, by sending online newsletters. It is possible to unsubscribe from such
                newsletters at any time via an 'unsubscribe' link in the relevant e-mail.<br/>
                If you have specified certain interests and preferences in your account, such as a product that you are
                looking for, we will also keep you informed of (potentially) interesting advertisements or products,
                provided that you have given permission for this. In this way, displayed advertisements are personalized
                based on your search behaviour, interests, (style) preferences and the like. Based on this, we can show
                you targeted (potentially) interesting advertisements, whether or not within a certain location, and
                better meet your wishes.<br/>
                <br/>
                <h1>Google Analytics<br/></h1>
                In order to optimize and improve the content and use of our website, we have access to the surfing
                behavior of website visitors. We do this with the help of Google Analytics. This functionality is
                set up in a privacy-friendly manner, whereby we have concluded a processing agreement with Google,
                the IP address is anonymized and no data is shared with Google.<br/>
                <br/>
                <h1> Sharing of personal data<br/></h1>
                We treat personal data provided to us confidentially and, in principle, do not share it with third
                parties, except in the following situations:<br/>
                1. Collapp also uses third parties for the provision of its services, for example IT suppliers and
                deliverers ('processors'). We have made agreements with such processors in processing agreements
                regarding the careful handling and security of personal data.<br/>
                2. Collapp may also provide or disclose certain personal data to third parties if we are or become
                required to do so by applicable law or court order.<br/>
                3. When you log in to our platform via your Facebook or other social media account, the relevant
                social media platform gains access to the personal data linked to your Collapp account. The privacy
                policy of the relevant social media platform applies to the processing of your personal data in that
                situation. Collapp has no influence on this.<br/>
                4. Collapp may share personal data with third parties in order to prevent, detect, investigate or
                mitigate fraud, security breaches or other potentially prohibited or illegal activities. Where
                appropriate, we will make agreements in processing agreements regarding the careful handling and
                security of personal data.<br/>
                <br/>
                In principle, personal data will not be passed on to parties or countries outside the EU European
                Economic Area (EEA). If the transfer of personal data to parties or countries outside the EEA is
                necessary, we will at all times comply with the legal requirements.<br/>
                <br/>
                <br/>
                <h1>Data security<br/></h1>
                We have taken appropriate technical and organizational measures to protect your protect personal
                data as optimally as possible against unauthorized access and misuse.<br/>
                <br/>
                Timing of data storage<br/>
                We do not store your personal data longer than necessary for the purposes for which the personal
                data was obtained. For example, the data of your Collapp account will in principle be kept until
                cancellation of that account. The data will then be deleted immediately.
                <br/>
                In some cases we need to keep certain personal data longer. This may be the case, for example, when
                a claim is still outstanding, specific statutory retention periods apply or (legal) disputes are
                pending.<br/>
                <br/>
                <h1>Your rights<br/></h1>
                You can view, check and/or change your personal data yourself via the settings in your Collapp
                account. In addition, you also have the right to request us to inspect, change, supplement, request
                deletion or data transfer of your personal data.<br/>
                <br/>
                You can also submit a request to limit a certain data processing, or object to this. A request to
                this effect can be sent to us in writing or by e-mail via the contact details above. We will respond
                within the legal time limits upon receipt of such a request.<br/>
                <br/>
                If you send us a request to delete your (personal data in your) Collapp account, we will delete your
                personal data as soon as possible, no later than 1 month after receipt of the request.<br/>
                <br/>
                <h1>Questions<br/></h1>
                If you have any questions, comments or complaints about the processing and/or protection of your
                personal data by us, you can contact us in writing or by e-mail using the contact details above. For
                the sake of completeness, we also point out that you have the right to submit a complaint to the
                supervisory authority. The Dutch supervisory authority with regard to data protection is the
                “Authority for Personal Data”.<br/>
                <br/>
                <h1>Applicable law<br/></h1>
                This Privacy Statement, as well as all disputes arising out of or related to this Privacy Statement,
                are exclusively governed by Dutch law.<br/>
                <br/>
                Amsterdam, January 2023<br/>

            </div>
        </>
    )
}

export default StatementSection;