import React from 'react'
import {InfoSec, InfoRow, InfoColumn, TextWrapper, TopLine, Heading, Subtitle, ImgWrapper, Img} from './InfoSection.elements'
import { Container, Button } from '../../globalStyles'
import { Link } from 'react-router-dom'

 const InfoSection = ({ 
    
    primary,
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart,
    start,
     left
}) => {if(left === true){
     return (
         <>
             <InfoSec lightBg={lightBg}>
                 <Container>
                     <InfoRow imgStart={imgStart}>
                         <InfoColumn>
                             <TextWrapper>
                                 <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                 <Heading lightText={lightText}>{headline}</Heading>
                                 <Heading lightTextDesc={lightTextDesc}>{description}</Heading>
                             </TextWrapper>
                         </InfoColumn>
                         <InfoColumn>
                             <ImgWrapper start={start}>
                                 <Img src={img} alt={alt} />
                             </ImgWrapper>
                         </InfoColumn>
                     </InfoRow>
                 </Container>
             </InfoSec>
         </>
     )
 }
 else{
     return (
         <>
             <InfoSec lightBg={lightBg}>
                 <Container>
                     <InfoRow imgStart={imgStart}>
                         <InfoColumn>
                             <ImgWrapper start={start}>
                                 <Img src={img} alt={alt} />
                             </ImgWrapper>
                         </InfoColumn>
                         <InfoColumn>
                             <TextWrapper>
                                 <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                 <Heading lightText={lightText}>{headline}</Heading>
                                 <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                             </TextWrapper>
                         </InfoColumn>
                     </InfoRow>
                 </Container>
             </InfoSec>
         </>
     )
 }
 }
    

export default InfoSection;