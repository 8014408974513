import React from 'react';
import { InfoSection } from '../../components';
import { homeObjOne } from './Data';
import TermsAndConditionsSection from "../../components/TermsOfConditions/TermsOfConditions";

function TermsOfService() {
  return (
    <>
      <TermsAndConditionsSection {...homeObjOne} />
    </>
  );
}

export default TermsOfService;