import React from 'react';
import { InfoSection, Pricing } from '../../components';
import {
    homeObjOne,
    homeObjThree,
    homeObjTwo,
    homeObjFour,
    step2,
    step1,
    step8,
    step7,
    step6,
    step5,
    step4, step3
} from './Data';

const Home = () => {
    return (
        <>
            <InfoSection {...homeObjOne} />
            <InfoSection {...step1} />
            <InfoSection {...step2} />
            <InfoSection {...step3} />
            <InfoSection {...step4} />
            <InfoSection {...step5} />
            <InfoSection {...step6} />
            <InfoSection {...step7} />
            <InfoSection {...step8} />
        </>
    )
}

export default Home;